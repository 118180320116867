@import "../config";
@keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}
.custome_table {
  width: 100%;
  margin-top: 1.5rem;
  background-color: $bg-gray;
  border-collapse: separate;
  border-spacing: 0 15px;
  &--th {
    color: $header-color;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    padding: 1rem;
    animation-name: example;
    animation-duration: 1s;
  }
  &--tr {
    margin-bottom: 1rem;
    animation-duration: 1s;
    animation-name: example;
    td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  &--td {
    background-color: $white;
    font-family: $font-family-base;
    padding: 1rem;
  }

}

.spinner {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
}
