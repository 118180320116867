@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;800&display=swap");
@import url("https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css");
// @import 'mapbox-gl/dist/mapbox-gl.css';
@import './Global/config';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main__content {
  height: calc(100vh - 81.5px);
  overflow-y: scroll;
  width: 100%;
  padding: 1.5rem;
  background-color: #f6f7f8;
  @media only screen and (max-width: 768px) {
    position: absolute
  }
}

.lazy_spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
}