$primary: #3a48a3;
// #F69F65;
$bg-gray: #F6F7F8;
$gray-light: #8B8F95;
$header-color: #9695AD;

$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-E9: #e9e9e9;
$gray-F9: #f9f9f9;
$gray-C6: #C6CCD0;
$gray-F2: #F2F4F8;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-a7: #A7A7A7;
$gray-ef: #EFEFEF;
$black: #000;

$grays: ();
$grays: map-merge(
    (
      "100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900
    ),
    $grays
);

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-headings: 'Poppins-Semi-Bold', sans-serif;
$font-family-sub-headings: 'Poppins-Medium', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;



$sidebar-bg-color: #ffffff;
$sidebar-color: #040405;
@import '~react-pro-sidebar/dist/scss/styles.scss';